<template>
	<div>
		<Header-Back :pageTitle="form.en"></Header-Back>
		<animationFrom v-if="form.id" :form="form" ref="animationFrom"></animationFrom>
		<div class="page-button">
			<el-button type="primary" @click="save">Save</el-button>
      <el-button type="info" v-if="form.status == 0" @click="onDisable">Disable</el-button>
      <el-button type="primary" v-else @click="onDisable">Enable</el-button>
			<el-button type="danger" icon="el-icon-delete-solid" @click="del">Delete</el-button>
		</div>
	</div>
</template>

<script>
import animationFrom from './components/animationFrom'
import { editAnimations, getAnimationsDetail, deleteAnimations, disableAnimations, enableAnimations } from "@/api/material";
export default {
	components:{
		animationFrom
	},
	data() {
    return {
      form: {},
    };
  },
  methods: {
    onDisable(){
      let { status, id} = this.form
      if(status == 0) {
        disableAnimations(id).then(() => {
          this.getData()
        })
      } else {
        enableAnimations(id).then(() => {
          this.getData()
        })
      }
    },
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id

        deleteAnimations(id).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    save() {
      let animationFrom = this.$refs.animationFrom,
      { ruleForm } = animationFrom,
      {pageId, id} = this.$route.params,
      form = {
        "page_id": pageId,
        "title": {
          "en": ruleForm.en,
          "zh_hant": ruleForm.zhHant,
          "zh_cn": ruleForm.zhCn
        },
        "thumbnail": ruleForm.thumbnail,
        "video_upload": {
          "english": ruleForm.english,
          "chinese": ruleForm.chinese,
          "hindi": ruleForm.hindi,
          "nepali": ruleForm.nepali,
          "tagalog": ruleForm.tagalog,
          "urdu": ruleForm.urdu
        }
      }

      animationFrom.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editAnimations(id, form).then(() => {
            this.$message.success('success')
            this.$router.go(-1)
          })
        }
      })
      
    },
    getData() {
      let id = this.$route.params.id
      getAnimationsDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          en: v.data.title.en,
          zhHant: v.data.title.zhHant,
          zhCn: v.data.title.zhCn,
          thumbnail: v.data.thumbnail,
          english: v.data.videoUpload.english,
          chinese: v.data.videoUpload.chinese,
          hindi: v.data.videoUpload.hindi,
          nepali: v.data.videoUpload.nepali,
          tagalog: v.data.videoUpload.tagalog,
          urdu: v.data.videoUpload.urdu,
          status: v.data.status
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.page-button{
	margin-top: 60px;
}
</style>