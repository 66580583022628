<template>
  <div class="import-edit">
    <Header-Back :pageTitle="ruleForm.title_en">
      <div class="last-updated">Last Updated {{ ruleForm.updated_at }}</div>
      <el-button type="primary" @click="save"> Save </el-button> 
      <el-button type="danger" @click="del"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button>
    </Header-Back>

    <div class="tab-nav">
      <div :class="['nav', importCate == 0 && 'select-nav']" @click="importCate = 0">Content</div>
      <div :class="['nav', importCate == 1 && 'select-nav']" @click="importCate = 1">Gallery</div>
    </div>

    <Content v-if="importCate == 0 && ruleForm.id" :form="ruleForm" @unifiedData="unifiedData" ref="content" ></Content>
    <Gallery v-if="importCate == 1 && ruleForm.id" :obj="ruleForm.galleries" @unifiedData="unifiedData" ref="gallery"></Gallery>
  </div>
</template>

<script>
import Content from './components/content.vue'
import Gallery from './components/gallery.vue'
import { getImportantAnnouncementsDetail, editImportantAnnouncements, deleteImportantAnnouncements } from '@/api/material'

export default {
  components: {
    Content,
    Gallery
  },
  data() {
    return {
      importCate: 0,

      ruleForm: {}
    }
  },
  methods: {
    // 统一数据
    unifiedData(moduleName, data) {
      data = JSON.parse(JSON.stringify(data))
      if(moduleName == 'content') {
        data.galleries = this.ruleForm.galleries
        this.ruleForm = {
          ...data,
        }
      } else {
        this.ruleForm.galleries = data
      }
    },
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteImportantAnnouncements(id).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    async save() {
      let content = this.$refs.content,
      gallery = this.$refs.gallery

      if(this.importCate == 0)
        this.unifiedData('content', content.ruleForm)
      else 
        this.unifiedData('gallery', gallery.galleries)
      let ruleForm = this.ruleForm,
      form = {
        'id': ruleForm.id,
        "date": ruleForm.date ? new Date(ruleForm.date).format('yyyy-MM-dd') : '',
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "content": {
          "en": ruleForm.content_en,
          "zh_hant": ruleForm.content_zhHant,
          "zh_cn": ruleForm.content_zhCn
        },
        "is_push_to_user": ruleForm.push_message,
        "push_message": {
          "en": ruleForm.push_message_en,
          "zh_hant": ruleForm.push_message_zhHant,
          "zh_cn": ruleForm.push_message_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            type: its.type,
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            }
          }
        })],
        "gallery_photos": [],
        "gallery_videos": []
      }

      ruleForm.galleries && ruleForm.galleries.filter(its => {
        if(its.type == 'video') {
          form.gallery_videos.push({
            "image_url": {
              "en": its.image_url_en || '',
              "zh_hant": its.image_url_zhHant || '',
              "zh_cn": its.image_url_zhCn || ''
            },
            "display_sort": Number(its.display_sort),
            "transcript_url": {
              "en": its.transcript_en,
              "zh_hant": its.transcript_zhHant,
              "zh_cn": its.transcript_zhCn
            },
            "video_link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "description": {
              "en": its.description_en,
              "zh_hant": its.description_zhHant,
              "zh_cn": its.description_zhCn
            }
          })
        } else {
          form.gallery_photos.push({
            "image_url": {
              "en": its.image_url_en || '',
              "zh_hant": its.image_url_zhHant || '',
              "zh_cn": its.image_url_zhCn || ''
            },
            "display_sort": Number(its.display_sort),
            "description": {
              "en": its.description_en,
              "zh_hant": its.description_zhHant,
              "zh_cn": its.description_zhCn
            }
          })
        }
      })
      
      if(this.importCate == 0) {
        content.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            editImportantAnnouncements(form.id, form).then(() => {
              this.$router.go(-1)
            })
          }
        })
      } else {
        // 表单校验
        let ruleForms = gallery.$refs['ruleForm'], is = true
        for(let i = 0; i < ruleForms.length; i++) {
          try {
            await ruleForms[i].validate()
          } catch {
            is = false
          }
        }
        if(is)
        editImportantAnnouncements(form.id, form).then(() => {
          this.$router.go(-1)
        })
      }
      
    },
    getData() {
      let id = this.$route.params.id
      getImportantAnnouncementsDetail(id).then(v => {
        let data = v.data
        this.ruleForm = {
          id: data.id, 
          title_en: data.title.en,
          title_zhHant: data.title.zhHant,
          title_zhCn: data.title.zhCn,
          date: data.date,
          content_en: data.content.en,
          content_zhHant: data.content.zhHant,
          content_zhCn: data.content.zhCn,
          push_message: data.isPushToUser,
          push_message_en: data.pushMessage.en,
          push_message_zhHant: data.pushMessage.zhHant,
          push_message_zhCn: data.pushMessage.zhCn,
          updated_at: new Date(data.updatedAt).format('yyyy-MM-dd hh:mm:ss'),
          documents: [...data.documents.map(its => {
            return {
              type: its.type,
              link_en: its.link.en,
              link_zhHant: its.link.zhHant,
              link_zhCn: its.link.zhCn,
            }
          })],
          galleries: []
        }

        let newArr = [...data.galleryVideos, ...data.galleryPhotos]
        .sort((a, b) => a.displaySort - b.displaySort)
        newArr.filter(its => {
          if(its.videoLink != undefined) {
            this.ruleForm.galleries.push({
              image_url_en: its.imageUrl.en,
              image_url_zhHant: its.imageUrl.zhHant,
              image_url_zhCn: its.imageUrl.zhCn,
              link_en: its.videoLink.en,
              link_zhHant: its.videoLink.zhHant,
              link_zhCn: its.videoLink.zhCn,
              transcript_en: its.transcriptUrl.en,
              transcript_zhHant: its.transcriptUrl.zhHant,
              transcript_zhCn: its.transcriptUrl.zhCn,
              description_en: its.description.en,
              description_zhHant: its.description.zhHant,
              description_zhCn: its.description.zhCn,
              display_sort: its.displaySort,
              type: 'video'
            })
          } else {
            this.ruleForm.galleries.push({
              image_url_en: its.imageUrl.en,
              image_url_zhHant: its.imageUrl.zhHant,
              image_url_zhCn: its.imageUrl.zhCn,
              description_en: its.description.en,
              description_zhHant: its.description.zhHant,
              description_zhCn: its.description.zhCn,
              display_sort: its.displaySort,
              type: 'photo'
            })
          }
        })
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.tab-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
  
  .nav {
    width: calc(50% - .3125rem);
    padding: .625rem;
    text-align: center;
    border-bottom: 3px solid #F0F0EF;
    font-size: 22px;
    font-weight: 700;
    color: #6D6D6D;
    cursor: pointer;
  }

  .select-nav {
    border-bottom-color: #124B80;
    color: #124B80;
  }
}
</style>