export default {
  'title_en': [
    { required: true, message: 'Please enter  English', trigger: 'blur' },
  ],
  'title_zhHant': [
    { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  ],
  'title_zhCn': [
    { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  ],
  'date': [
    { required: true, message: 'Please choose  Date', trigger: 'blur' },
  ],
  'documents[0].link_en': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  ],
  'documents[0].link_zhHant': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  ],
  'documents[0].link_zhCn': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},

  ],

  'link_en': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  ],
  'link_zhHant': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  ],
  'link_zhCn': [
    {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  ],
}