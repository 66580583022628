import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'
import ElementUi from 'element-ui'
import * as filters from './filters'
import echarts from 'echarts'
import store from '@/store'
import { mixin } from '@/mixin/index'
import locale from 'element-ui/lib/locale/lang/en'
import HeaderBack from './components/headerBack/headerBack.vue'
import Browse from './components/browse/index.vue'
import Pagination from "@/components/Pagination";
import EditDiv from "@/components/editDiv";
import Print from 'vue-print-nb-jeecg'
import Tinymce from "@/components/Tinymce";
import JsonExcel from '@/utils/vue-json-excel'
import './directive'

Vue.component('downloadExcel', JsonExcel)

Vue.use(Print)
Vue.prototype.$echarts = echarts
import './utils/date-format'

Vue.use(ElementUi, {locale})
Vue.config.productionTip = false

Vue.component('Tinymce', Tinymce)
Vue.component('Browse', Browse)
Vue.component('HeaderBack', HeaderBack)
Vue.component('Pagination', Pagination)
Vue.component('EditDiv', EditDiv)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.mixin({
  ...mixin
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
