<template>
  <div class="notification">
    <el-form ref="ruleForm" :rules="rules" label-position="top" :model="ruleForm" label-width="80px">
      <el-form-item label="Section" prop="type">
        <el-select v-model="ruleForm.type" >
          <el-option v-for="(its, idx) in templates" :key="idx"
            :label="its.title" :value="its.type">
          </el-option> 
        </el-select>
      </el-form-item>
      <el-form-item label="Device ID" prop="device_id">
        <el-input v-model="ruleForm.device_id"></el-input>
      </el-form-item>
      <el-form-item label="Title" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="Message" prop="message">
        <el-input v-model="ruleForm.message"></el-input>
      </el-form-item>
      <el-form-item label="Article Reference ID" prop="reference_id">
        <el-input v-model="ruleForm.reference_id"></el-input>
      </el-form-item>
      <div class="page-button">
        <el-button type="primary" @click="send">Send</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { sendPushNotification } from '@/api/notification.js'

export default {
  data() {
    return {
      ruleForm: {
        type: null,
        device_id: '',
        title: '',
        message: '',
        reference_id: null
      },
      rules: {
        'device_id': [
          { required: true, message: 'Please enter Device ID', trigger: 'blur' },
        ],
        'title': [
          { required: true, message: 'Please enter Title', trigger: 'blur' },
        ],
        'message': [
          { required: true, message: 'Please enter Message', trigger: 'blur' },
        ],
        'reference_id': [
          { required: true, message: 'Please enter Reference ID', trigger: 'blur' },
        ],
      },
      templates: [
        {
          title: 'WSA - Text Version',
          type: 0
        },
        {
          title: 'Systemic Safety Alert-Text Version',
          type: 1
        },
        {
          title: 'Important Announcement',
          type: 2
        },
      ]
    }
  },
  methods: {
    send() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let form = {...this.ruleForm}
          form.reference_id *= 1
          sendPushNotification(form).then(() => {
            this.$message.success('success')
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.div-item {
  position: relative;
  padding: 30px;
  background: #f5f5f5;
  margin: 20px 0;
  .el-icon-close{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}

.add-btn {
  width: 120px;
  height: 44px;
  border: 1px solid #1a74b0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.page-button {
  margin-top: 60px;
}
</style>