<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary" @click=" dialogVisible=true">
      插入圖片
    </el-button>
    <el-dialog :visible.sync="dialogVisible">
      <el-upload
        class="avatar-uploader"
        :action="action"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <div>
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </div>
      </el-upload>
      <el-button @click="dialogVisible = false">
        Cancel
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        Confirm
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      imageUrl: ''
    }
  },
  computed: {
    headers() {
      const token = getToken()
      return { Authorization: `Bearer ${token}` }
    },
    action() {
      const base_api = process.env.VUE_APP_BASE_API || '/api'
      return base_api + '/files'
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('successCBK', this.imageUrl)
      this.dialogVisible = false
      this.imageUrl = ''
    },
    handleAvatarSuccess(res) {
      this.imageUrl = res
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isGIF = file.type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error('Supported image type: jpg, png, gif, bmp')
      }
      if (!isLt2M) {
        this.$message.error('up to 10MB')
      }
      return isJPG || isPNG || isGIF && isLt2M
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
