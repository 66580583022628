<template>
  <div>
    <Header-Back :pageTitle="form.titleEn">
      <div class="last-updated">Last Updated {{ form.updatedAt }}</div>
      <el-button type="primary" icon="el-icon-printer" @click="print">Print</el-button>
    </Header-Back>
    <el-form ref="form" label-position="top" :model="form" label-width="80px">
      <div class="column-title">Title</div>
      <el-form-item label="English">
        <div class="content">{{form.titleEn}}</div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
       <div class="content">{{form.titleZhHant}}</div>
      </el-form-item>

      <el-form-item label="Simplified Chinese">
       <div class="content">{{form.titleZhCn}}</div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Date of Accident</div>
      <el-form-item label="">
        <div class="content">{{form.accidentDate.substring(0, 7)}}</div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Place of Accident</div>

      <el-form-item label="English">
        <div class="content">{{form.placeOfAccidentEn}}</div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div class="content">{{form.placeOfAccidentZhHant}}</div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content">{{form.placeOfAccidentZhCn}}</div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Summary</div>
      <el-form-item label="English">
        <div class="content" v-html="form.summaryEn"></div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div class="content" v-html="form.summaryZhHant"></div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content" v-html="form.summaryZhCn"></div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">
        Work Safety Alert for Contractors/Proprietors/Employers
      </div>
      <el-form-item label="English">
        <div v-html="form.wsaEn"></div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
       <div v-html="form.wsaZhHant"></div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div v-html="form.wsaZhCn"></div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Reference</div>
      <template v-for="(item, index) in form.references">
       <div class="div-item" :key="index + 'references'">
        <el-form-item label="English Title">
          <div class="content">{{item.titleEn}}</div>
        </el-form-item>
        <el-form-item label="Traditional Chinese Title">
         <div class="content">{{item.titleZhHant}}</div>
        </el-form-item>
        <el-form-item label="Simplified Chinese Title">
          <div class="content">{{item.titleZhCn}}</div>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item label="Link - English">
             <div class="content">{{item.linkEn}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Link - Traditional Chinese">
             <div class="content">{{item.linkZhHant}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Link - Simplified Chinese">
              <div class="content">{{item.linkZhCn}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Sorting Order">
          <div v-if="item.sortingOrder" class="content">{{item.sortingOrder}}</div>
        </el-form-item>
      </div>
      </template>
     
      <el-divider></el-divider>
      <div class="column-title">Push Notification and Share Message</div>
      <el-form-item label="">
        <el-checkbox v-model="form.isPushToUser" disabled
          >Push to user (Send push notification to user)</el-checkbox
        >
      </el-form-item>
      <el-form-item label="English">
        <div class="content" v-html="form.pushMessageEn"></div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
       <div class="content" v-html="form.pushMessageZhHant"></div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content" v-html="form.pushMessageZhCn"></div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Link(s)</div>
      <div class="div-item" v-for="(its, idx) in form.link" :key="idx">
        <el-row type="flex" :gutter="20">
          <el-col :span="5">
            <el-form-item>
              {{ types[its.type] }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item label="English">
              <div class="content">{{its.en}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Traditional Chinese">
              <div class="content">{{its.zhHant}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Simplified Chinese">
              <div class="content">{{its.zhCn}}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="page-button">
        <el-button type="primary" @click="edit">Edit</el-button>
        <el-button type="success" @click="save">Save & Publish</el-button>
        <el-button type="primary" icon="el-icon-printer" @click="print">Print</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getTextVersionsDetail, editTextVersions } from "@/api/material";
import {convertLocal, convertLine} from './handleData/handleData'

export default {
  data() {
    return {
      types: ['PDF', 'External Link', 'Photo', 'Video'],
      form: {
        title: {},
        summary: {}
      },
      localData: null,
    };
  },
  methods: {
    print() {
      localStorage.setItem('printData', JSON.stringify(this.form))
      let a = document.createElement('a')
      a.href = location.pathname + '#/print'
      a.setAttribute('target', '_blank')
      a.click()
    },
    edit() {
      let {pageId, id} = this.$route.params
      this.$router.push(`/template/wsa/textVersion/${pageId}/${id}/edit`);
    },
    getData() {
      let id = this.$route.params.id
      getTextVersionsDetail(id).then(v => {
        this.form = convertLocal(v.data)
        // 存储本地数据
        this.localData = this.form
      })
    },
    save() {
      let ruleForm = this.form,
      form = convertLine(this.localData)
      
      editTextVersions(ruleForm.id, form).then(() => {
        this.$message.success('success')
        this.$router.go(-1)
      })
    },
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
}
.page-button {
  margin-top: 60px;
}
.content {
  word-wrap: break-word;
  font-size: 18px;
  font-weight: 700;
}
</style>