<template>
  <div>
    <Header-Back :pageTitle="form.titleEn">
      <div class="last-updated">Last Updated {{ form.updatedAt }}</div>
    </Header-Back>
    <textVersionFrom :form="form" v-if="form.id" ref="textVersionFrom"></textVersionFrom>
    <div class="page-button">
      <el-button type="primary" @click="draft">Save</el-button>
      <el-button type="success" @click="save">Save & Publish</el-button>
      <el-button type="primary" @click='preview'>Preview</el-button>
      <el-button type="danger" icon="el-icon-delete-solid" @click="del">Delete</el-button>
    </div>
  </div>
</template>

<script>
import textVersionFrom from "./components/textVersionFrom";
import { editTextVersions, getTextVersionsDetail, deleteTextVersions } from "@/api/material";
import {convertLine, convertLocal} from './handleData/handleData'

export default {
  components: {
    textVersionFrom,
  },
  data() {
    return {
      form: {}
    };
  },
  created(){
    
  },
  methods: {
    preview() {
      let {pageId, id} = this.$route.params
      this.$router.push(`/template/wsa/textVersion/${pageId}/${id}/preview`);
    },
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteTextVersions(id).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    draft() {
      let child = this.$refs.textVersionFrom,
      ruleForm = child.ruleForm,
      form = convertLine(ruleForm)
      form.is_publish = false
      
      this.doSave(form)
    },
    save() {
      let child = this.$refs.textVersionFrom,
      ruleForm = child.ruleForm,
      form = convertLine(ruleForm)

      this.doSave(form)
    },
    doSave(form) {
      let child = this.$refs.textVersionFrom,
      ruleForm = child.ruleForm

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editTextVersions(ruleForm.id, form).then(() => {
            this.$message.success('success')
            this.$router.go(-1)
          })
        }
      })
    },
    getData() {
      let id = this.$route.params.id
      getTextVersionsDetail(id).then(v => {
        this.form = convertLocal(v.data)
      })
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.page-button{
  margin-top: 60px;
}
</style>