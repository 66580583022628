<template>
  <div>
     <Header-Back :hideBack="true" pageTitle="Push Notification List">
       <el-button type="primary" icon="el-icon-upload2">Export</el-button>
    </Header-Back>
    <el-form
      :inline="true"
      label-position="top"
      :model="formLabelAlign"
      class="demo-form-inline"
    >
      <el-form-item label="Title">
        <el-input v-model="formLabelAlign.likeKeywords" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="Type">
        <el-select v-model="formLabelAlign.type" clearable  placeholder="">
          <el-option v-for="(item,index) in pushNotificationListType" :key="index" :label="item.label" :value="item.value"></el-option>
         
        </el-select>
      </el-form-item>
      <el-form-item label="Date">
            <el-date-picker
              v-model="formLabelAlign.pushedAt"
              clearable 
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="">
            </el-date-picker>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column width="500" label="Title"> 
        <template slot-scope="scope">
           <span v-if="scope.row.title" @click="onTitle(scope.row)">{{scope.row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type"> 
        <template slot-scope="scope">
           <span v-if="scope.row.type!=null">{{scope.row.type | pushNotificationListType}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pushedAt" label="Date & Time">
        <template slot-scope="scope">
           <span v-if="scope.row.pushedAt">{{scope.row.pushedAt | formatDateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="total > 0"
      :total="total"
      :page.sync="formLabelAlign._start"
      :limit.sync="formLabelAlign._limit"
      @pagination="getPushNotifications"
    />
  </div>
</template>

<script>
import {getPushNotifications} from "@/api/pushNotification";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      total:0,
      formLabelAlign: {
          likeKeywords: null,
          pushedAt: null,
          type: null,
          _start:1,
          _limit:10
      },
      tableData:[
       
      ]
    };
  },
  watch:{
    formLabelAlign:{
      handler(){
        this.getPushNotifications()
      },
      deep:true
    }
  },
  created() {
    this.getPushNotifications()
  },
  methods:{
    onTitle(row){
      let route = ``

      switch(row.type) {
        case 0: 
          route = `/template/wsa/textVersion/${row.referenceId}`; break
        case 1: 
          route = `/template/ssa/${row.referenceId}`; break
        case 2: 
          route = `/template/ia/${row.referenceId}`; break
        case 3: 
          route = `/new`; break
      }
      this.$router.push(route)
    },
    goToDetail(){
       this.$router.push({path:'/pushNotificationList',params:{id:1
      }})
    },
    getPushNotifications(){
      let from= {
        _start:this.formLabelAlign._start-1,
        _limit:this.formLabelAlign._limit,
        likeKeywords:this.formLabelAlign.likeKeywords,
        pushedAt:this.formLabelAlign.pushedAt,
        type:this.formLabelAlign.type
      }
      getPushNotifications(from).then(res=>{
         this.tableData = res.data
         this.total = Number(res.headers["x-total-count"]);
      })
    },
    
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box{
    padding-top:30px;
    border-top: 1px solid #D9D9D9;
}
</style>