<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Admin Permission">
       <exportExcel  ref="orderExcel" :tableLabels="tableLabels" path="Admin Permission" fileName="Admin Permission" :labels="adminsAll" buttonTitle="Export"></exportExcel>
       <el-button type="primary" @click="goToAdd" icon="el-icon-plus">Create Admin</el-button>
    </Header-Back>

    <el-form
      :inline="true"
      label-position="top"
      :model="formLabelAlign"
      class="demo-form-inline"
    > 
     <el-form-item label="Search">
        <el-input v-model="formLabelAlign.likeKeywords" clearable placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="Section">
        <el-select v-model="formLabelAlign.sectionId" clearable placeholder="">
          <el-option v-for="(item,index) in sectionList" :label="item.title.en" :value="item.id" :key="index"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="page-title">List</div>
    <el-table :data="admins" style="width: 100%">
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="email" label="Email"> </el-table-column>
      <el-table-column prop="roleName" label="Role"> </el-table-column>
      <el-table-column label="Admin Rights">
        <template slot-scope="scope">
            <div v-show="sectionList.length !== scope.row.sections.length">
              <div v-for="(item,index) in scope.row.sections" :key="index">
                <span v-if="item.title">{{item.title.en}}</span>
              </div>
            </div>
            <div v-show="sectionList.length == scope.row.sections.length">All</div>
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope">
          <el-switch
            :active-value="0"
            :inactive-value="1"
            @input="onSuspend(scope.row, scope.row.status ==0 ? 'Success to suspend user account' : 'Success to active user account')"
            :value="scope.row.status"
            >
          </el-switch>&nbsp;&nbsp;&nbsp;
          <span>{{scope.row.status | statusFilters}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Edit">
        <template slot-scope="{row}">
          <div class="button-img"><el-button type="text" @click="goToEdit(row.id)"> <img src="@/assets/images/Edit - large.png"> </el-button></div>
        </template>
      </el-table-column>
      <el-table-column label="Delete">
        <template slot-scope="{row}">
          <div class="button-img"><el-button type="text" @click="onDelete(row.id)"> <img src="@/assets/images/mingcute_delete-2-fill.png"> </el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="total > 0"
      :total="total"
      :page.sync="formLabelAlign._start"
      :limit.sync="formLabelAlign._limit"
      @pagination="getAdmins"
    />
  </div>
</template>

<script>
import exportExcel from "@/components/exportExcel";
import {getSections} from '@/api/section';
import { getAdmins,suspendAdmins,deleteAdmins } from "@/api/user";
import Pagination from "@/components/Pagination";
export default {
  filters: {
    statusFilters(v){
      return v == 0 ? 'Active' : 'Suspend'
    }
  },
  components: {
    Pagination,
    exportExcel
  },
  data() {
    return {
      tableLabels:[
        {
          label:'Name',
          prop:'name'
        },
        {
          label:'Email',
          prop:'email'
        },
        {
          label:'Role',
          prop:'roleName'
        },
        {
          label:'ID',
          prop:'id'
        },
        {
          label:'Admin rights ',
          prop:'sections'
        },
      
        {
          label:'status',
          prop:'status'
        },
      
      ],
      sectionList:[],
      total:0,
      formLabelAlign: {
          likeKeywords: null,
          sectionId: null,
          _start:1,
          _limit:10
      },
      admins:[],
      adminsAll:[],
    };
  },
  watch:{
    formLabelAlign:{
      handler(v){
        if(v!=null){
         this.getAdmins()
        }
      },
      deep:true
    }
  },
  created(){
    this.getAdmins()
    this.getAllAdmins()
    this.getSections()
  },
  methods:{
    onDelete(id){
      //弹窗
      this.$confirm('Confirm to delete?')
          .then(() => {
            this.deleteAdmins(id)
            
          })
    },
    onSubmit(){
      this.getAdmins()
    },
    onSuspend(row,message){
       let rowStatusText = row.status == 0 ? 'Suspend' : 'Active'
       this.$confirm(`Confirm to ${rowStatusText}?`)
          .then(() => {
            row.status = !row.status
            this.suspendAdmins(row,message)
          })
    },
    goToEdit(id){
      this.$router.push({path:'/adminPermission/'+id+'/edit'} )
    },
    goToAdd(){
      this.$router.push({path:'/adminPermission/adminPermissionAdd'} )
    },
    getSections(){
      getSections().then((v)=>{
         this.sectionList = v.data
      })
    },
    deleteAdmins(id){
      deleteAdmins(id).then(()=>{
        this.$message({
          message: 'Delete success!',
          type: 'success'
        })
        this.getAdmins()
      })
    },
    suspendAdmins(row,message){
      suspendAdmins(row.id).then(()=>{
        this.$message({
          message:  message,
          type: 'success'
        })
        this.getAdmins()
      })
    },
    getAdmins(){
      let formLabelAlign = {
        likeKeywords: this.formLabelAlign.likeKeywords,
        sectionId: this.formLabelAlign.sectionId,
        _start: this.formLabelAlign._start-1,
        _limit: this.formLabelAlign._limit

      }
      getAdmins(formLabelAlign).then((res)=>{
          this.admins =  res.data
          this.total = Number(res.headers["x-total-count"]);
      })
    },
    getAllAdmins(){
      let formLabelAlign = {
        likeKeywords: this.formLabelAlign.likeKeywords,
        sectionId: this.formLabelAlign.sectionId,
        _start: 0,
        _limit: 99999999

      }
      getAdmins(formLabelAlign).then((res)=>{
          this.adminsAll =  res.data
          
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box{
    padding-top:30px;
    border-top: 1px solid #D9D9D9;
}
.button-img img {
  width: 30px;
  height: auto;
}
</style>