<template>
  <div>
    <div class="disclaimer-box">
      <div class="column-title">Disclaimer</div>
      <el-form label-position="top" label-width="80px" :model="disclaimer">
        <template v-for="(key, idx) in Object.keys(language)">
          <el-form-item :label="key" :key="idx">
            <Tinymce v-model="disclaimer[language[key]]"></Tinymce>
          </el-form-item>
        </template>
      </el-form>

      <div class="column-title" v-if="as">Accessibility Statement</div>
      <el-form v-if="as" label-position="top" label-width="80px" :model="accessibilityStatement">
        <template v-for="(key, idx) in Object.keys(language)">
          <el-form-item :label="key" :key="idx">
            <Tinymce v-model="accessibilityStatement[language[key]]"></Tinymce>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <el-button type="success" @click="onSave">Save</el-button>
  </div>
</template>

<script>
import {getPagesDetail,editPages} from '@/api/pages'
export default {
  props: {
    id: {},
    as: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      type: '',
      disclaimer: {
        "en": "",
        "zhHant": "",
        "zhCn": ""
      },
      accessibilityStatement: {
        "en": "",
        "zhHant": "",
        "zhCn": ""
      },
      language: {
        'English': 'en',
        'Traditional Chinese': 'zhHant',
        'Simplified Chinese': 'zhCn',
      }
    };
  },
  watch:{
    id: function() {
      let pageId = this.$route.params.pageId
      this.getPagesDetail(pageId)
    }
  },
  created(){
  },
  methods: {
    getPagesDetail(pageId){
      getPagesDetail(pageId).then((re)=>{
        this.$emit('setPageTitle', re.data.path)
        this.disclaimer = re.data.disclaimer
        this.accessibilityStatement = re.data.accessibilityStatement
      }) 
    },
    onSave() {
      let form = {
        disclaimer: this.disclaimer,
        accessibility_statement: this.accessibilityStatement,
      }
      editPages(this.$route.params.pageId, form).then(()=>{

      })
    },
  },
  mounted() {
    let pageId = this.$route.params.pageId
    this.getPagesDetail(pageId)
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box {
  padding-top: 30px;
  border-top: 1px solid #d9d9d9;
}
</style>