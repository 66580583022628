<template>
  <div>
    <Header-Back pageTitle="Edit New"> 
       <span class="last-lint">Last Updated {{ form.updatedAt | formatDateTime }}</span>
    </Header-Back>
    <newFrom :form="form" ref="form"></newFrom>
    <div class="btn-group">
      <el-button type="success" @click="onSave">Save</el-button>
      <el-button type="primary" @click="onPreview">Preview</el-button>
      <el-button type="danger" icon="el-icon-delete-solid" @click="onDelete">Delete</el-button>
    </div>
  </div>
</template>

<script>
import { getNewsDetail, editNews,deleteNews } from "@/api/news";
import newFrom from "./components/newFrom";
import {initializeForm,transformForm} from './newDataForm'
export default {
  components: { newFrom },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: { },
    };
  },
  created() {
    this.getNewsDetail()
  },
  methods: {
    onPreview(){
      this.$router.push({path:'/new/'+this.id} )
    },
    onDelete(){
      this.$confirm('Confirm to delete?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteNews()
      })
    },
    onSave(){
       this.$refs.form.$refs.form.validate((valid) => {
          if (valid) {
           this.editNews()
          } else {
           this.$nextTick(() => {
            const isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
            return false;
          }
        });
     
    },
    deleteNews(){
      deleteNews(this.id).then(() => {
        this.$router.push({path:'/new'} )
      })
    },
    getNewsDetail() {
      getNewsDetail(this.id).then((v) => {
        this.form = initializeForm(v.data) 
      });
    },
    editNews() {
      editNews(this.id, transformForm(this.form)).then(() => {
        this.$router.push({path:'/new'} )
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-group{
  margin-top:20px;
}
</style>