<template>
  <div>
    <el-image v-if="imageurl" style="display: block;margin-bottom: 10px;width: 100px; height: 100px" :src="imageurl"></el-image>
    <input v-show="false" ref="ff" type="file" @change="onUpload" />
    <el-button type="primary" v-if="isIcon"  @click="onSelectImageButton"
      >Browse</el-button>
    <img v-else class="page-content-img cursorPointer" @click="onSelectImageButton" src="@/assets/images/edit.png">
  </div>
</template>

<script>
import { file } from "@/api/files";
export default {
  props: {
    fileType: {
      type: Array,
      default: () => {
        return ['png', 'jpg',  'bmp', ]
      }
    },
    imageurl: {
      type: String,
      default: "",
    },
    uploadUrl: {
      type: String,
      default: "/files",
    },
    buttonText: {
      type: String,
      default: "Select Image",
    },
    isImgDom:{
      type:Boolean,
      default: false
    },
    isIcon:{
      type:Boolean,
      default: false
    },
    isSizes:{
      type:Boolean,
      default: false
    },
    width:{
      type:Number,
      default: 504
    },
    height:{
      type:Number,
      default: 282
    }
  },
  methods: {
    onSelectImageButton() {
      this.$refs.ff.click();
    },
    onUpload(e) {  
      if (e.target.files[0].type != null) {
        var fileSize = e.target.files[0].size
        var size = fileSize / 1024
        const that = this
        const fileType = this.fileType
        const isFileType = fileType.includes(
            e.target.files[0].name.split('.').pop().toLowerCase()
        )
         if (!isFileType) {
          that.$message.error('文件格式错误，应为: jpeg,png,bmp,gif,svg,webp')
          return false
        }
        if(size > 5000 && this.isSizes){
            that.$message.error('up to 5MB')
            return false
        }
        if(this.isImgDom){
          this.isSize(e)     
        } else {
          this.uploadFormData(e)
        }
      }
    },
    async isSize(file){
          let _URL = window.URL || window.webkitURL;
          let imgDom  = new Image();
          let width = this.width;
          let height = this.height;
          let imgfile = _URL.createObjectURL(file.target.files[0])
          imgDom.src = imgfile;
          new Promise(function (resolve, reject){
            imgDom.onload =  () =>  {
              let isSize = imgDom.width >= width && imgDom.height >= height
              isSize ? resolve() : reject();  
            }
          }).then(()=>{
            this.uploadFormData(file)
          }).catch(()=>{
             this.$message.error(`Wrong image size: at least '${this.width}'px，x' ${this.height}'px。`)
          })
          
          
    },
    uploadFormData(e) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      file(this.uploadUrl,formData)
        .then((v) => {
          this.$emit("childByValue", v.data);
          this.$message({
            type: "success",
            message: "successfully upload!",
          });
        }).finally(()=>{
           this.$refs.ff.value = null
        })
      
    },
  },
};
</script>

<style lang="scss" scoped>
.themeButton {
  color: #fff;
  
}
</style>