<template>
  <div class="box">
    <div class="page">
      <div class="title">404</div>
      <div class="permissions">很抱歉，沒有此頁面</div>
      <el-button type="primary" @click="onBack">返回主頁</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
.box{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.page {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.title{
  font-size: 300px;
  color: #AFAEAD;
}
.permissions{
  font-size: 28px;
  margin-bottom: 10px;
}
</style>