<template>
  <div>
    <Header-Back pageTitle="New"></Header-Back>

    <FormComponent :from="form" ref="FormComponent"></FormComponent>

    <div class="btn">
      <el-button type="success" @click="save"> Save </el-button> 
    </div>
  </div>
</template>

<script>
import FormComponent from './components/form.vue'
import { addAnimations } from "@/api/material.js";

export default {
  components: {
    FormComponent
  },
  data() {
    return {
      form: {
        en: '',
        zhHant: '',
        zhCn: '',
        thumbnail: '',
        english: '',
        chinese: '',
        hindi: '',
        nepali: '',
        tagalog: '',
        urdu: '',
      },
    };
  },
  methods: {
    formattedForm() {
      this.languages.forEach((key) => {
        this.form.title[key] = this.form[`title${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(this.form, `title${key}`, this.form.title[key]);
      });
    },

    save() {
      let FormComponent = this.$refs.FormComponent,
      { ruleForm } = FormComponent,
      form = {
        "page_id": this.$route.params.pageId,
        "title": {
          "en": ruleForm.en,
          "zh_hant": ruleForm.zhHant,
          "zh_cn": ruleForm.zhCn
        },
        "thumbnail": ruleForm.thumbnail,
        "video_upload": {
          "english": ruleForm.english,
          "chinese": ruleForm.chinese,
          "hindi": ruleForm.hindi,
          "nepali": ruleForm.nepali,
          "tagalog": ruleForm.tagalog,
          "urdu": ruleForm.urdu
        }
      }

      FormComponent.$refs['form'].validate((valid) => {
        if (valid) {
          addAnimations(form).then(() => {
            this.$message.success('success')
            this.$router.go(-1)
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 20px;
}
</style>