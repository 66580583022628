import {languages} from '@/biz/static-data' 
let from = {
  id: null,
  content: {
    en: null,
    zhHant: null,
    zhCn: null,
  },
}
export function initializeForm(data=from) {
    from.id = data.id || null 
    languages.filter((key) => {
        from[`content${key}`] = data.content[key] || ''
    });
    return from
}
export function transformForm(data) {
    from.id = data.id || null
    languages.filter((key) => {
        from.content[key]= data[`content${key}`] || ''

    });
    return from

}