<template>
  <div>
    <div class="page-title">Overview</div>
    <div v-show="adminUserInfo.position == 1">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="4">
          <div class="column-title">Banners</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="toEditRouter('bannerEdit')">Manage</el-button>
        </el-col>
      </el-row>
      <div class="banner">
        <vue-seamless-scroll
          :data="listData"
          :class-option="classOption"
          class="warp"
        >
          <span slot="left-switch" class="left-arrow"></span>
          <span slot="right-switch" class="right-arrow"></span>
          <ul class="ul-item">
            <li class="li-item" v-for="(item, index) in listData" :key="index">
              <img :src="item.imageUrl.en" alt="">
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>

    <div class="column-list">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="4">
          <div class="column-title">First Row Sections</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" v-if="adminUserInfo.position == 1" @click="toEditRouter('firstRowSectionEdit')">Manage</el-button>
        </el-col>
      </el-row>

      <div class="row-list">
        <div class="col-box" v-for="(item,index) in sections" :key="index" @click="clickSection(item)">
          <img :src="item.iconUrl" class="img-icon">
          <div class="col-text">{{item.title.en}}</div>
          <div v-if="item.pages.length == 2">
            <span class="col-version" @click="onEdit(item, 'textVersion')">Text Version</span>
            <el-divider direction="vertical"></el-divider>
            <span class="col-version" @click="onEdit(item, 'animation')">Animation</span>
          </div>
          <div class="col-button" v-if="item.status == 1">Disabled</div>
        </div>
      </div>
    </div>

    <div class="column-list">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="6">
          <div class="column-title">Second Row Sections</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" v-if="adminUserInfo.position == 1" @click="toEditRouter('secondRowSectionEdit')">Manage</el-button>
        </el-col>
      </el-row>

      <div class="row-list">
        <div class="col-box" v-for="(item,index) in seconds" :key="index" @click="clickSection(item)">
          <img :src="item.iconUrl" class="img-icon">
          <div class="col-text">{{item.title.en}}</div>
          <div v-if="item.pages.length == 2">
            <span class="col-version" @click="onEdit(item, 'textVersion')">Text Version</span>
            <el-divider direction="vertical"></el-divider>
            <span class="col-version" @click="onEdit(item, 'animation')">Animation</span>
          </div>
          <div class="col-button" v-if="item.status == 1">Disabled</div>
        </div>
      </div>
    </div>
    
    <div class="others-box" v-show="adminUserInfo.position == 1">
      <div class="page-title">Others</div>
      <el-row type="flex" justify="space-between" align="middle" class="others-list">
        <el-col :span="6">
          <div class="others-title">What’s New</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onGoTo('/new')">Edit</el-button>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle" class="others-list">
        <el-col :span="6">
          <div class="others-title">About Us</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onGoTo('/aboutUs')">Edit</el-button>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="others-list">
        <el-col :span="6">
          <div class="others-title">Notices</div>
        </el-col>
      </el-row>
      <div style="background:#F5F5F5;margin-bottom: 20px;">
        <el-row type="flex"  justify="space-between" align="middle" class="others-row">
          <el-col :span="6">
            <div class="notices-title">Disclaimer</div>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="onGoTo('/notices/0')">Edit</el-button>
          </el-col>
        </el-row>
        <el-row type="flex"  justify="space-between" align="middle" class="others-row">
          <el-col :span="6">
            <div class="notices-title">Copyright Notice</div>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="onGoTo('/notices/1')">Edit</el-button>
          </el-col>
        </el-row>
        <el-row type="flex"  justify="space-between" align="middle" class="others-row">
          <el-col :span="6">
            <div class="notices-title">Privacy Policy</div>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="onGoTo('/notices/2')">Edit</el-button>
          </el-col>
        </el-row>
        <el-row type="flex"  justify="space-between" align="middle" class="others-row">
          <el-col :span="9">
            <div class="notices-title">Accessibility Statement</div>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="onGoTo('/notices/3')">Edit</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row type="flex" justify="space-between" align="middle" class="others-list">
        <el-col :span="6">
          <div class="others-title">App Version</div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onGoTo('/appVersion')">Edit</el-button>
        </el-col>
      </el-row>

      <div class="others-box">
        <div class="page-title">Test for Push Notification</div>
        <Notification></Notification>
      </div>
    </div>
    
  </div>
</template>

<script>
import Notification from './notification/notification.vue';
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getBanners } from '@/api/banner.js'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    vueSeamlessScroll,
    Notification
  },
  data() {
    return {
      pageSize:1,
      pageNum: 4,
      sections:[
        { title: {en: ''}, icon: '', pages: []},
      ],
      seconds:[
        { title: {en: 'l'}, iconUrl: '', pages: [] },
      ],
      classOption: {
        navigation: true,//左右方向的滚动是否显示控制器按钮
        limitMoveNum: 2,
        switchSingleStep: 140,
        direction: 3,
      },
      type: ['textVersion', 'animation', 'ssa', 'csa', 'osh', 'ia', 'NotifyYourWorkplace']
    };
  },
  computed: {
    ...mapState(['Section']),
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
    listDatas: function () {
      return this.listData.slice((this.pageSize-1)*this.pageNum, this.pageSize*this.pageNum)
    }
  },
  watch: {
    Section: function() {
      this.getSections()
    }
  },
  methods: {
    
    cancel(){
      this.centerDialogVisible =false
    },
    ...mapMutations(['setSection']),
    // 点击banner
    clickBanner(row) {
      let {pages, link} = row
      if(pages.length > 0) {
        let route
        let [ page ] = pages
        if(page.type == 0) route = `/template/wsa/${this.type[page.type]}/${page.id}?sectionName=${ page.path }`
        else route = `/template/${this.type[page.type]}/${page.id}?sectionName=${ page.path }`

        this.$router.push(route)
      } else {
        let a = document.createElement('a')
        a.href = link
        a.setAttribute('target', '__blank')
        a.click()
      }
    },
    // 点击section
    clickSection(row) {
      let {pages, link} = row
      if(pages.length == 2) {
        return ;
      }
      if(pages.length > 0) {
        let route
        if(pages.length == 1) {
          let [ page ] = pages
          route = `/template/${this.type[page.type]}/${page.id}?sectionName=${ row.title.en }`
        }

        this.$router.push(route)
      } else {
        let a = document.createElement('a')
        a.href = link.en
        a.setAttribute('target', '__blank')
        a.click()
      }
    },
    onGoTo(route){
     this.$router.push(route)
    },
    onEdit(its, router){
      let route
      if(router == 'textVersion') {
        route = `/template/wsa/textVersion/${its.pages[0].id}?sectionName=${ its.title.en }`
      } else {
        route = `/template/wsa/animation/${its.pages[1].id}?sectionName=${ its.title.en }`
      }
      this.$router.push(route)
    },
    toEditRouter(route) {
      this.$router.push(`/overview/${route}`)
    },
    getBanners() {
      getBanners().then(v => {
        this.listData = v.data
      })
    },
    getSections() {
      let section = JSON.parse(JSON.stringify(this.Section))
      this.sections = []
      this.seconds = []
      section.filter(its => its.displayType == 0 ? this.sections.push(its) : this.seconds.push(its))
    }
  },
  mounted() {
    this.getBanners()
    let warp = document.querySelector('.warp'),
    ul = warp.querySelector('ul'),
    liWidth = warp.offsetWidth / 3
    this.classOption.switchSingleStep = Math.floor(liWidth)
    ul.style.width = `${liWidth * this.listData.length}px`
    ul.style.height = `${warp.offsetHeight}px`
    this.getSections()
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 12.5rem;
  padding: 30px 120px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  margin: 1.25rem 0;
  position: relative;

  .warp {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;

    & > div:nth-type-last-child(1) {
      width: 100% !important;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      &.ul-item {
        display: flex;
  
        .li-item {
          width: 33%;
          height: 100%;
          margin-right: 10px;
          line-height: 120px;
          background-color: #999;
          color: #fff;
          text-align: center;
          font-size: 30px;
          
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  
  
    .left-arrow,
    .right-arrow {
      position: relative;
      display: inline-block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      cursor: pointer;
      &::before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        top: 12px;
        left: 15px;
        border: 2px solid #fff;
      }
    }
   
    //左右切换按钮的样式
    .left-arrow {
      background-image: url('../../assets/images/arrow_left.png');
      background-size: 100% 100%;
    }
   
    .right-arrow {
      background-image: url('../../assets/images/arrow_right.png');
      background-size: 100% 100%;
    }
   
    .left-arrow::before {
      display: none;
    }
   
    .right-arrow::before {
      display: none;
    }
  }
}


.column-list{
  margin-bottom: 30px;
}
.row-list{
  display: grid;
  gap: 20px;
  margin-top: 20px;
  grid-template-columns: repeat(4, 1fr);
}
.col-box{
  width: 100%;
  position: relative;
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background-color: #F5F5F5;

  .img-icon {
    width: 80px;
    height: 80px;
  }
}
.col-text{
color: var(--black, #000);
text-align: center;
/* H3 */
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 700;
}
.col-version{
color: var(--Secondary, #1A74B0);
text-align: center;
/* H3 */
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 700;
}
.col-button{
position: absolute;
top: 20px;
right: 20px;
display: inline-flex;
height: 31px;
padding: 2px 8px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--Dark-Grey, #6D6D6D);
color: var(--Dark-Grey, #6D6D6D);
text-align: center;
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
}
.others-list{
display: flex;
padding: 20px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
background: var(--Light-Grey, #F5F5F5);
margin-bottom: 20px;
}
.others-list:nth-child(4){
margin-bottom: 0;
}
.others-box{
  width: 652px;
}
.others-title{
color: var(--black, #000);
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 700;
}

.others-row{
  display: flex;
  padding: 10px 24px 10px 36px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
</style>

<style lang="scss">
  .warp {
    & > div:nth-last-of-type(1) {
      width: 100% !important;

      & > div {
        width: 100% !important;

        ul {
          width: 100% !important;
        }
      }
    }
  }
</style>