<template>
  <div class="">
    <Header-Back :pageTitle="form.title_en"></Header-Back>

    <FileForm v-if="form.id" :form="form" ref="fileForm"></FileForm>

    <div class="">
      <el-button type="primary" @click="save"> Save </el-button> 
      <el-button type="danger" @click="del"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button> 
    </div>
  </div>
</template>

<script>
import FileForm from '../compoments/fileForm.vue'
import { editAshTrainings, getAshTrainingsDetail, deleteAshTrainings } from '@/api/material'

export default {
  components: {
    FileForm
  },
  data() {
    return { 
      form: {}
    }
  },
  methods: {
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteAshTrainings(id).then(()=>{
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    save() {
      let child = this.$refs.fileForm,
      ruleForm = child.ruleForm,
      form = {
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "documents": [
          {
            "title": {
              "en": ruleForm.description_en,
              "zh_hant": ruleForm.description_zhHant,
              "zh_cn": ruleForm.description_zhCn
            },
            "subtitle": {
              "en": ruleForm.description_en,
              "zh_hant": ruleForm.description_zhHant,
              "zh_cn": ruleForm.description_zhCn  
            },
            "type": Number(ruleForm.doc_type),
            "link": {
              "en": ruleForm.doc_link_en,
              "zh_hant": ruleForm.doc_link_zhHant,
              "zh_cn": ruleForm.doc_link_zhCn
            },
            "sorting_order": 0
          }
        ],
        "sub_pages_ids": []
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editAshTrainings(ruleForm.id, form).then(() => {
            this.$message.success('modify successfully!')
            this.$router.go(-1)
          })
        }
      })
      
    },
    getData() {
      let id = this.$route.params.id
      getAshTrainingsDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          title_en: v.data.title.en,
          title_zhHant: v.data.title.zhHant,
          title_zhCn: v.data.title.zhCn,
          description_en: v.data.description.en,
          description_zhHant: v.data.description.zhHant,
          description_zhCn: v.data.description.zhCn,
          ...v.data.documents.map(its => {
            return {
              doc_type: its.type,
              doc_link_en: its.link.en,
              doc_link_zhHant: its.link.zhHant,
              doc_link_zhCn: its.link.zhCn,
            }
          })[0]
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>

</style>