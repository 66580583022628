<template>
  <div class="">
    <Header-Back :pageTitle="form.title_en"></Header-Back>

    <PageForm v-if="form.id" :form="form" ref="pageForm"></PageForm>

    <div class="">
      <el-button type="primary" @click="save"> Save </el-button> 
      <el-button type="danger" @click="del"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button> 
    </div>
  </div>
</template>

<script>
import PageForm from '../compoments/pageForm.vue'
import { editAshTrainings, getAshTrainingsDetail, deleteAshTrainings } from '@/api/material'

export default {
  components: {
    PageForm
  },
  data() {
    return { 
      form: {}
    }
  },
  methods: {
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteAshTrainings(id).then(()=>{
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    save() {
      let child = this.$refs.pageForm,
      ruleForm = child.ruleForm,
      form = {
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            "title": {
              "en": its.title_en,
              "zh_hant": its.title_zhHant,
              "zh_cn": its.title_zhCn
            },
            "subtitle": {
              "en": its.subtitle_en,
              "zh_hant": its.subtitle_zhHant,
              "zh_cn": its.subtitle_zhCn
            },
            "type": Number(its.type),
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "sorting_order": its.sorting_order
          }
        })].sort((a, b) => a.sorting_order - b.sorting_order),
        "sub_pages_ids": ruleForm.sub_pages_ids || []
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editAshTrainings(ruleForm.id, form).then(() => {
            this.$message.success('modify successfully!')
            this.$router.go(-1)
          })
        }
      })
      
    },
    getData() {
      let id = this.$route.params.id
      getAshTrainingsDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          title_en: v.data.title.en,
          title_zhHant: v.data.title.zhHant,
          title_zhCn: v.data.title.zhCn,
          description_en: v.data.description.en,
          description_zhHant: v.data.description.zhHant,
          description_zhCn: v.data.description.zhCn,
          documents: [...v.data.documents.map(its => {
            return {
              title_en: its.title.en,
              title_zhHant: its.title.zhHant,
              title_zhCn: its.title.zhCn,
              subtitle_en: its.subtitle.en,
              subtitle_zhHant: its.subtitle.zhHant,
              subtitle_zhCn: its.subtitle.zhCn,
              type: its.type,
              link_en: its.link.en,
              link_zhHant: its.link.zhHant,
              link_zhCn: its.link.zhCn,
              sorting_order: its.sortingOrder
            }
          })],
          sub_pages_ids: v.data.subPagesIds
        }

      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>

</style>