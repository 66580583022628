<template>
  <div class="">
    <el-form label-position="top" v-model="ruleForm" :rules="rules" :model="ruleForm" ref="ruleForm" label-width="100px" class="ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English" style="width: 100%" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" style="width: 100%" prop="title_zhHant">
        <el-input v-model="ruleForm.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" style="width: 100%" prop="title_zhCn">
        <el-input v-model="ruleForm.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Document No.</div>
      <el-form-item style="width: 40%">
        <el-input type="textarea" :rows="1" v-model="ruleForm.document_number"></el-input>
      </el-form-item>

      <div class="line"><span class="span-label-text">*</span>Date</div>
      <el-form-item prop="date" :rules="[{ required: true, message: 'Please select the date', trigger: 'blur' }]">
        <el-date-picker
          v-model="ruleForm.date"
          type="month"
          value-format="yyyy-MM"
          placeholder="- Please select -"
          style="width: 40%">
        </el-date-picker>
      </el-form-item>

      <div class="line">Push Notification and Share Message</div>
      <el-form-item label="">
        <el-checkbox v-model="ruleForm.push_message">
          Push to user (Send push notification to user)
        </el-checkbox>
      </el-form-item>
      <el-form-item label="English" style="width: 100%">
     
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" style="width: 100%">
       
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" style="width: 100%">
      
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">PDF</div>
      <div class="group">
        <el-form-item label="Link - English" prop="pdf_en">
          <edit-div v-model="ruleForm.pdf_en"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Traditional Chinese" prop="pdf_zhHant">
          <edit-div v-model="ruleForm.pdf_zhHant"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Simplified Chinese" prop="pdf_zhCn">
          <edit-div v-model="ruleForm.pdf_zhCn"></edit-div>
        </el-form-item>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        title_en: [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ],
        title_zhHant: [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ],
        title_zhCn: [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ],
       
        date: [
          { required: true, message: 'Date can not be empty', trigger: 'blur'}
        ],
        'pdf_en': [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ],
        'pdf_zhHant': [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ],
        'pdf_zhCn': [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ],
      },
    }
  },
  methods: {
    verifyLink() {
      let leng = this.ruleForm.length
      for(let i = 0; i < leng; i++) {
        this.rules[`documents[${i}].pdf_en`] = [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ]
        this.rules[`documents[${i}].pdf_zhHant`] = [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ]
        this.rules[`documents[${i}].pdf_zhCn`] = [
          { type: 'url', message: `Please enter the correct one Link`, trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
  }

}
</script>

<style lang="scss" scoped>
.ruleForm {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F5F5F5;
  padding: 20px;
  box-sizing: border-box;

  & > * {
    width: 32%;
  }
}
</style>