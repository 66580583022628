import news from '@/views/others/new'
import aboutUs from '@/views/others/about-us'
import notices from '@/views/others/notices'
import newAdd from '@/views/others/new-add'
import newEdit from '@/views/others/new-edit'
import newDetail from '@/views/others/new-detail'
export default [
    {
        path:'/new', component: news
    },
    {
        path:'/aboutUs', component: aboutUs
    },
    {
        path:'/notices/:type', props: (route) => ({ type: Number(route.params.type) }), component: notices
    },
    {
        path:'/new/newAdd', component: newAdd
    },
    {
        path:'/new/:id/edit',props: (route) => ({ id: Number(route.params.id) }), component: newEdit
    },
    {
        path:'/new/:id',props: (route) => ({ id: Number(route.params.id) }), component: newDetail
    },
]