<template>
  <div>
    <Header-Back :pageTitle="form.title_en">
      <div class="last-updated">Last Updated {{ form.updated_at }}</div>
    </Header-Back>

    <Form ref="form"></Form>

    <div class="page-button">
      <el-button type="primary" class="save-button-size" @click="save">Save</el-button>
      <el-button type="primary" class="preview-button-size" @click="preview">Preview</el-button>
      <el-button type="danger" class="delete-button-size" icon="el-icon-delete-solid" @click="del">Delete</el-button>
    </div>
  </div>
</template>

<script>
import Form from './components/form.vue';
import { editSystemicSafetyAlerts, getSystemicSafetyAlertsDetail, deleteSystemicSafetyAlerts } from "@/api/material.js";

export default {
  components: {
    Form
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    preview() {
      let {pageId, id} = this.$route.params
      
      this.$router.push(`/template/ssa/systemicSafetyAlert/${pageId}/${id}/preview`);
    },
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteSystemicSafetyAlerts(id).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.$router.go(-1)
        })
      })
    },
    save() {
      let child = this.$refs.form,
      ruleForm = child.ruleForm,
      form = {
        "page_id": this.$route.params.pageId,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn,
        },
        "document_number": ruleForm.document_number,
        "date": new Date(ruleForm.date).format('yyyy-MM-dd'),
        "is_push_to_user": ruleForm.push_message,
        "push_message": {
          "en": ruleForm.push_message_en,
          "zh_hant": ruleForm.push_message_zhHant,
          "zh_cn": ruleForm.push_message_zhCn,
        },
        "pdf": {
          "en": ruleForm.pdf_en,
          "zh_hant": ruleForm.pdf_zhHant,
          "zh_cn": ruleForm.pdf_zhCn,
        },
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editSystemicSafetyAlerts(ruleForm.id, form).then(() => {
            this.$router.go(-1)
          })
        }
      })
    },
    getData() {
      let id = this.$route.params.id
      getSystemicSafetyAlertsDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          title_en: v.data.title.en,
          title_zhHant: v.data.title.zhHant,
          title_zhCn: v.data.title.zhCn,
          document_number: v.data.documentNumber,
          date: v.data.date,
          push_message: v.data.isPushToUser,
          push_message_en: v.data.pushMessage.en,
          push_message_zhHant: v.data.pushMessage.zhHant,
          push_message_zhCn: v.data.pushMessage.zhCn,
          pdf_en: v.data.pdf.en,
          pdf_zhHant: v.data.pdf.zhHant,
          pdf_zhCn: v.data.pdf.zhCn,
          updated_at: new Date(v.data.updatedAt).format('yyyy-MM-dd'),
        }
        this.$refs.form.ruleForm = this.form
      })
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>

</style>