<template>
  <div>
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <el-button type="primary" icon="el-icon-upload2" @click="exportData">Export</el-button>
      <el-button type="primary" @click="add" icon="el-icon-plus">New</el-button>
    </Header-Back>

    <div class="search-func">
      <div class="search">
        <div class="label">Search</div>
        <div class="value">
          <el-input prefix-icon="el-icon-search" v-model="listQ.like_keywords"
            placeholder="Keywords" @input="getData"
          ></el-input>
        </div>
      </div>
      <div class="date">
        <div class="label">Date</div>
        <div class="value">
          <el-date-picker v-model="listQ.accident_date" type="year"
          style="width: 100%" value-format="yyyy" @change="getData">
        </el-date-picker>
        </div>
      </div>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        empty-text="No record"
      >
        <el-table-column
          :prop="its.prop"
          :label="its.label"
          :width="its.width"
          v-for="(its, idx) in tableCate"
          :key="idx"
        >
        </el-table-column>
        <el-table-column label="Preview" width="100">
        
          <template slot-scope="scope">
            <img
              src="@/assets/images/preview.png"
              @click="preview(scope.row)"
              class="table-icon"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="Edit" width="100">
        
          <template slot-scope="scope">
            <img
              src="@/assets/images/Edit - large.png"
              @click="edit(scope.row)"
              class="table-icon"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="Delete" width="100">
          <template slot-scope="scope">
            <img
              src="@/assets/images/mingcute_delete-2-fill.png"
              class="table-icon"
              alt=""
              @click="del(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :autoScroll="false"
      :page.sync="listQ._start"
      :limit.sync="listQ._limit"
      layout="total, prev, pager, next"
      @pagination="getData"
    />

    <disclaimer v-if="pageId" :id="pageId"></disclaimer>
  </div>
</template>

<script>
import disclaimer from "@/components/disclaimer";
import { getTextVersions, deleteTextVersions, getExportTextVersions } from "@/api/material";
import exportExcel from './exportData'

export default {
  components: { disclaimer },
  data() {
    return {
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      date: "",
      tableCate: [
        { label: "ID", prop: "id", width: "100" },
        { label: "Title", prop: "title.en", width: "" },
        { label: "Status", prop: "status", width: "100" },
        { label: "Date", prop: "accidentDate", width: "140" },
        { label: "Push to user", prop: "isPushToUser", width: "150" },
      ],
      tableData: [],

      total: 0,
      listQ: {
        like_keywords: '',
        accident_date: '',
        _start: 1,
        _limit: 20,
      },
      pageId: null,
      pageTitle: ''
    };
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId
      this.listQ = {
        like_keywords: '',
        accident_date: '',
        _start: 1,
        _limit: 10,
      }

      this.getData()
    }
  },
  methods: {
    exportData() {
      let obj = {
        page_id: this.pageId,
        like_keywords: '',
        accident_date: '',
        _start: this.listQ._start - 1,
        _limit: 9999
      }

      getExportTextVersions(obj)
        .then(({data}) => {
          exportExcel(data)
        })
    },
    add() {
      this.$router.push(`/template/wsa/textVersion/${this.pageId}/add`);
    },
    preview(row) {
      this.$router.push(`/template/wsa/textVersion/${this.pageId}/${row.id}/preview`);
    },
    edit(row) {
      this.$router.push(`/template/wsa/textVersion/${this.pageId}/${row.id}/edit`);
    },
    del(id) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        deleteTextVersions(id).then(() => {
          this.$message.success('success')
          this.getData()
        })
      })
       
    },
    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName + ' - Text Version'
      let {listQ} = this, 
      form = {
        page_id: this.pageId,
        like_keywords: listQ.like_keywords,
        accident_date: listQ.accident_date ? new Date(listQ.accident_date).format('yyyy') : '',
        _start: listQ._start - 1,
        _limit: listQ._limit,
      };

      getTextVersions(form).then((v) => {
        v.data.filter(its => {
          its.isPushToUser = its.isPushToUser ? 'Yes' : 'No'
          its.accidentDate = new Date(its.accidentDate).format('yyyy-MM')
          its.status = its.isPublish ? 'Published' : 'Draft'
        })
        this.tableData = v.data;
        this.total = Number(v.headers['x-total-count'])
      });
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box {
  padding-top: 30px;
  border-top: 1px solid #d9d9d9;
}
.search-func {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .search {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .date {
    width: 35%;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}
</style>