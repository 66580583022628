<template>
  <div>
    <Header-Back pageTitle="New"></Header-Back>
    <textVersionFrom :form="form" ref="textVersionFrom"></textVersionFrom>
    <div class="page-button">
      <el-button type="primary" @click="draft">Save</el-button>
      <el-button type="success" @click="save">Save & Publish</el-button>
    </div>
  </div>
</template>

<script>
import textVersionFrom from "./components/textVersionFrom";
import { addTextVersions } from "@/api/material";
import {convertLine} from './handleData/handleData'

export default {
  components: {
    textVersionFrom,
  },
  data() {
    return {
      form: {
        pageId: this.$route.params.pageId,
        titleEn: '',
        titleZhHant: '',
        titleZhCn: '',
        accidentDate: "",
        placeOfAccidentEn: '',
        placeOfAccidentZhHant: '',
        placeOfAccidentZhCn: '',
        summaryEn: '',
        summaryZhHant: '',
        summaryZhCn: '',

        wsaEn: '',
        wsaZhHant: '',
        wsaZhCn: '',
        references: [
          {
            titleEn: '',
            titleZhHant: '',
            titleZhCn: '',
            linkEn: '',
            linkZhHant: '',
            linkZhCn: '',
            sortingOrder: 0,
          },
        ],
        isPushToUser: false,
        pushMessageEn: '',
        pushMessageZhHant: '',
        pushMessageZhCn: '',
        link: [
          {
            type: 0,
            en: '',
            zhHant: '',
            zhCn: '',
          }
        ]
      },
    };
  },
  methods: {
    draft() {
      let child = this.$refs.textVersionFrom,
      ruleForm = child.ruleForm,
      form = convertLine(ruleForm)
      form.is_publish = false
      this.doSave(form)
    },
    save() {
      let child = this.$refs.textVersionFrom,
      ruleForm = child.ruleForm,
      form = convertLine(ruleForm)

      this.doSave(form)
    },
    doSave(form) {
      let child = this.$refs.textVersionFrom

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addTextVersions(form).then(() => {
            this.$message.success('success')
            this.$router.go(-1)
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-button {
  margin-top: 60px;
}
</style>