export default {
  'titleEn': [
    { required: true, message: 'Please enter  English', trigger: 'blur' },
  ],
  'titleZhHant': [
    { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  ],
  'titleZhCn': [
    { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  ],
  'accidentDate': [
    { required: true, message: 'Please choose  Date of Accident', trigger: 'blur' },
  ],
  'references[0].titleEn': [
    { required: true, message: 'Please enter  English', trigger: 'blur' },
  ],
  'references[0].titleZhHant': [
    { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  ],
  'references[0].titleZhCn': [
    { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  ],
}