import {Message} from 'element-ui'
import {file} from '@/api/files.js' 

/**
 * 上传文件
 * @param {array} suffix 
 *    ['image/png', 'image/jpeg']
 * @param {object} area 
 *    {width: 180, height: 180}
 * @param {number | string} size 
 *     单位：M
 * @param {function} callBack 
 *     回调
 */
export function updateFile(suffix, area, maxSize, callBack) {
  let file = document.createElement('input')
  file.type = 'file'
  file.onchange = () => {
    let files = file.files[0],
    {size, type} = files,
    // 创建临时图片 获取图片宽高
    tempImg = URL.createObjectURL(files),
    img = new Image()
    img.src = tempImg
    
    setTimeout(() => {
      let {width, height} = img
      if(suffix.length > 0 && !suffix.includes(type)) {
        let imgType = suffix.map(its => its.replace('image/', '').toLowerCase())
                      .join(',')
        Message.error(`Please select ${imgType} image`)
      } else if(width < area.width || height < area.height) {
        Message.error(`at least ${area.width}px(H) x ${area.height}px(W)`)
      } else if(maxSize < (size / 1024 ** 2)) {
        Message.error(`Maximum file size ${maxSize} MB`)
      } else {
        uploadFormData(file, callBack)
      }
    }, 1000);
  }

  file.click()
}

function uploadFormData(dom, callBack) {
  const formData = new FormData();
  
  formData.append("file", dom.files[0]);
  file('/files', formData)
    .then((v) => {
      callBack(v.data)
      Message({ type: "success", message: "successfully upload!"});
    })
    .finally(() => {
      dom.value = null;
    });
}