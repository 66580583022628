<template>
  <div class="">
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <div @click="openAddBtn" class="btn-default"> 
        <img src="@/assets/images/charm_cross.png" alt=""> New 
        <div class="add-child" tabindex="1" @blur="add_switch = false" v-if="add_switch">
          <span @mousedown="addFolder">Folder</span>
          <span @mousedown="addPage">Page</span>
          <span @mousedown="addFile">File</span>
        </div>
      </div> 
    </Header-Back>

    <div class="pages" v-for="(its, idx) in folder" :key="idx + 'folder'">
      <div class="page">
        <div class="text" @click="toFolder(its)">
          <span>{{ its.title.en }}</span>
          <img v-if="its.type == 0" src="@/assets/images/arrow_right.png" class="arrow-right" alt="">
        </div>
        <div class="operation">
          <div class="type">{{ types[its.type] }}</div>
          <div class="order">
            <img src="@/assets/images/order_up.png" alt="" @click="up(idx)">
            <img src="@/assets/images/order_down.png" alt="" @click="down(idx)">
          </div>

          <img src="@/assets/images/Edit - large.png" alt="" v-if="its.type == 0" @click="editFolder(its)">
          <img src="@/assets/images/Edit - large.png" alt="" v-else-if="its.type == 1" @click="editPage(its)">
          <img src="@/assets/images/Edit - large.png" alt="" v-else @click="editFile(its)">
          <img src="@/assets/images/mingcute_delete-2-fill.png" alt="" @click="del(its)">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getAshTrainings, deleteAshTrainings, getAshTrainingsSorts } from '@/api/material'

export default {
  data() {
    return {
      pages: [
        {id: 1, title: 'Occupational Safety and Health Training Courses(Free of Charge)'}
      ],
      types: ['Folder', 'Page', 'File'],
      folder: [],
      pageId: null,
      pageTitle: 'OSH Training',
      add_switch: false
    }
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId

      this.getData()  
    }
  },
  methods: {
    openAddBtn() {
      this.add_switch = true
      this.$nextTick(() => {
        let addBtn = document.querySelector('.add-child')
        addBtn.focus()
      })
    },
    up(idx) {
      let folder = JSON.parse(JSON.stringify(this.folder))

      if(idx != 0) {
        [folder[idx], folder[idx - 1]] = [folder[idx - 1], folder[idx]]
      }

      this.order(folder)
    },
    down(idx) {
      let folder = JSON.parse(JSON.stringify(this.folder))

      if(idx != 0) {
        [folder[idx], folder[idx + 1]] = [folder[idx + 1], folder[idx]]
      }

      this.order(folder)
    },
    order(arr) {
      arr = arr.map((its, idx) => ({id: its.id, sort: idx}))
      getAshTrainingsSorts(arr).then(() => {
        this.getData()
      })
    },
    del(its) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        deleteAshTrainings(its.id).then(() => {
          this.$message.success('Successfully deleted！')
          this.getData()
        })
      })
    },
    toFolder(row) {
      if(row.type == 0)
        this.$router.push(`/template/osh/${this.pageId}/folder/${row.id}?sectionName=${this.pageTitle}`)
    },
    addFile() {
      this.$router.push(`/template/osh/file/${this.pageId}/add`)
    },
    addPage() {
      this.$router.push(`/template/osh/page/${this.pageId}/add`)
    },
    addFolder() {
      this.$router.push(`/template/osh/folder/${this.pageId}/add`)
    },
    editFile(row) {
      this.$router.push(`/template/osh/file/${this.pageId}/${row.id}/edit`)
    },
    editPage(row) {
      this.$router.push(`/template/osh/page/${this.pageId}/${row.id}/edit`)
    },
    editFolder(row) {
      this.$router.push(`/template/osh/folder/${this.pageId}/${row.id}/edit`)
    },
    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName
      
      getAshTrainings({page_id: this.pageId}).then(v => {
        v.data.sort((a, b) => a.sort - b.sort)
        this.folder = v.data.map(its => {
          return {
            childSwitch: false,
            ...its
          }
        })
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.btn-default {
  position: relative;
  width: 122px;
  height: 44px;
  padding: 5px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #fff;
  background-color: #1A74B0;
  cursor: pointer;

  img {
    width: auto;
    height: 20px;
    margin-right: 5px;
    vertical-align: sub;
  }

  .add-child {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    padding: 20px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 44px;
      background-color: #1A74B0;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    span:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
}

.pages {
  width: 100%;

  .page {
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    color: #000000;
    background-color: #F5F5F5;
    position: relative;

    .text {
      display: flex;
      align-items: center;

      img {
        width: 28px;
        height: auto;
      }

      span {
        max-width: 800px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .arrow-left {
        margin-right: 10px;
      }

      .arrow-right {
        margin-left: 10px;
      }
    }

    .operation {
      display: flex;
      align-items: center;

      .type {
        margin-right: 60px;
        width: 102px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #D53E19;
        color: #D53E19;
        font-size: 20px;
        font-weight: 700;
      }

      .order {
        width: 110px;
        margin-right: 60px;

        img {
          margin-left: 20px;
        }
      }

      img {
        width: 32px;
        height: auto;
        margin-left: 10px;
        cursor: pointer;
      }
    }

  }
  
  .pages-child {
    width: calc(100% - 50px);
    margin: 0 0 20px 50px;
    box-sizing: border-box;
    font-size: 18px;
    color: #000000;
    position: relative;

    .page:after {
      content: '';
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      margin: auto;
      width: 40px;
      height: 40px;
      background-image: url('../../../assets/images/arrow_next.png');
      background-size: 100% 100%;
    }
  }
}

</style>