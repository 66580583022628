<template>
  <div class="content">
    <div class="security-box">
      <p class="work-text">OSH 2.0</p>
      <p class="backend-text">Backend Portal</p>
      <el-form
        label-position="top"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        :model="formLabelAlign"
      >
        <p class="login-text">Login</p>
        <el-form-item label="">
          <el-input
            v-model="formLabelAlign.username"
            placeholder="User Name"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            show-password
            v-model="formLabelAlign.password"
            placeholder="Password"
          ></el-input>
        </el-form-item>
        <span class="forgot-text" @click="onForgotPassword"
          >Forgot password?</span
        >
        <el-form-item>
          <el-button type="primary" @click="onLoginButtonClick"
            >LOGIN</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <loadingDialog :center-dialog-visible="centerDialogVisible"></loadingDialog>
    <slotDialog
      :centerDialogVisible="centerDialogVisibleSlotDialog"
      @submit="submitDialog"
      @handleClose="handleClose"
    ></slotDialog>
  </div>
</template>

<script>
import slotDialog from "@/components/slotDialog";
import moment from "moment";
import { login } from "@/api/security";
import { setToken, setUserInfo } from "@/utils/auth";
import loadingDialog from "@/components/loadingDialog/index";
import uuidv1 from "uuid/v1";

export default {
  components: { loadingDialog, slotDialog },
  data() {
    return {
      loginData:{},
      uuid: "",
      centerDialogVisibleSlotDialog: false,
      centerDialogVisible: false,
      formLabelAlign: {
        username: "",
        password: "",
      },
      rules: {},
    };
  },
  created() {
    this.uuid = uuidv1();
  },
  methods: {
    handleClose(){
       this.centerDialogVisibleSlotDialog = false;
    },
    submitDialog() {
      this.centerDialogVisibleSlotDialog = false;
      this.getAdminRole(() => {
        this.$router.push({ path: "/overview" });
      }, this.loginData);
    },
    onForgotPassword() {
      this.$router.push({ path: "/forgotPassword" });
    },
    onLoginButtonClick() {
      this.login();
    },
    login() {
      this.centerDialogVisible = true;
      let form = {
        username: this.formLabelAlign.username,
        password: this.formLabelAlign.password,
        deviceId: this.uuid,
      };
      login(form)
        .then((v) => {
          setUserInfo("refreshToken", v.data.refreshToken);
          setUserInfo("userInfo", v.data);
          setToken(v.data.accessToken);
          this.loginData = v.data
          let a = moment(v.data.changePasswordAt);
          let b = moment(new Date());
          if (b.diff(a, "days") > 90) {
            this.centerDialogVisibleSlotDialog = true;
          } else {
            this.getAdminRole(() => {
              this.$router.push({ path: "/overview" });
            }, v.data);
          }
        })
        .finally(() => {
          this.centerDialogVisible = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  min-height: 100% !important;
  background-color: #f1f8f9;
}
.security-box {
  width: 600px;
}
.security-box p {
  text-align: center;
}
.el-form {
  width: 100%;
  display: block;
  background-color: #fff;
  padding: 20px;
}
.el-form-item {
  text-align: center;
  color: #fff !important;
}
::v-deep {
  .el-button--primary {
    width: 100%;
  }
}

.logo-img {
  display: block;
  width: 368px;
  margin-left: 174px;
}
.work-text {
  color: var(--Main, #124b80);
  text-align: center;
  /* H1 */
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}
.backend-text {
  color: var(--black, #000);
  text-align: center;
  /* H2 */
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.login-text {
  color: var(--black, #000);
  text-align: center;
  /* H1 */
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}
.forgot-text {
  display: inline-block;
  color: var(--Secondary, #1a74b0);
  /* Link */
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--Secondary, #1a74b0);
  cursor: pointer;
}
</style>