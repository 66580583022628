import Vue from "vue"
import VueRouter from "vue-router"
import login from './modules/security'
import overview from './modules/overview'
import interstitialAds from './modules/interstitialAds'
import pushNotificationList from './modules/pushNotificationList'
import setting from './modules/setting'
import others from './modules/others'
import report from './modules/report'
import appVersion from './modules/appVersion'
import template from './modules/template'
import { getToken } from '@/utils/auth'

import printNew from '@/components/printNew/index'
import print from '@/components/Print/index'
import layout from '@/layout/index'
import absent from '@/views/404'
Vue.use(VueRouter)

export const routes = [
  {
    path: '/', redirect:'/overview', component: layout, children: [
      ...overview,
      ...interstitialAds,
      ...pushNotificationList,
      ...report,
      ...setting,
      ...appVersion,
      ...others,
      ...template,
    ],
    
  },
  {
    path: '/printNew', component:printNew
  },
  {
    path: '/print', component: print
  },
  ...login,
  { path: '*', component: absent }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const whitelist = [
    '/login',
    '/forgotPassword'
  ]


router.beforeEach(async(to, from, next) => {

    if (getToken()) {
      if (to.path === '/login') {
        next({ path: '/' })
      } 
      else {
        next()
      }
    } else {
      if (whitelist.includes(to.path) ) {
        next()
      } else {
        next({ path: '/login' })
      }
    }
  })


export default router