<template>
  <div class="container" style="height: 100% !important;">
    <el-container>
      <el-header><Header></Header></el-header>
      <el-container style="height: 100% !important;">
        <el-aside><Menu></Menu></el-aside>
        <el-main> <router-view style="height: 100% !important;" class="main-router-view" /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Menu from "./Menu";
import Header from "./Header";
export default {
  components: { Menu, Header },
  computed: {
    defaultActive() {
      let isShop = true;
      if (this.$route.meta.activeMenu == "Detail") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        isShop = false;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        isShop = true;
      }
      return isShop;
    },
  },
};
</script>
<style lang="scss" scoped>
.container{
  height: 100% !important;
}
.container, .is-vertical{
  padding: 0;
  margin: 0;
}
.el-header {
  height: auto !important;
  padding: 0;
}
.el-main {
  padding: 0;
}
.main-router-view {
  flex-grow: 1;
  padding: 30px 60px;
}
::v-deep{
  .el-aside {
    width: 380px !important;
  }
}
</style>