<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Share Message"></Header-Back>
    <el-form ref="form" label-position="top" :model="formLabelAlign" label-width="80px">
      <template v-for="(item, index) in languages">
        <el-form-item :label="setLabel(item)" :key="index">
          <el-input
              type="textarea"
              :rows="6"
              v-model="formLabelAlign[`content${item}`]"
            ></el-input>
        </el-form-item>
      </template>
      <el-button type="success" @click="onSave">Save</el-button>
    </el-form>
  </div>
</template>

<script>
import { getShareMessages, editShareMessages } from "@/api/setting";
export default {
  data() {
    return {
       formLabelAlign: {
        id: null,
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
      },
    };
  },
  created(){
    this.getShareMessages();
  },
  methods: {
     formattedForm() {
      this.languages.forEach((key) => {
        this.formLabelAlign.content[key] = this.formLabelAlign[`content${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(
          this.formLabelAlign,
          `content${key}`,
          this.formLabelAlign.content[key]
        );
      });
    },
    setLabel(lan) {
      switch (lan) {
        case "en":
          return "English";
        case "zhHant":
          return "Traditional Chinese";
        case "zhCn":
          return "Simplified Chinese";
        default:
          return "";
      }
    },
    onSave() {
      this.editShareMessages();
    },
    getShareMessages() {
      getShareMessages().then((v) => {
         this.formLabelAlign = v.data[0]
         this.initializeForm()
      });
    },
    editShareMessages() {
      this.formattedForm()
      editShareMessages( this.formLabelAlign.id,this.formLabelAlign).then(() => {
         this.$message({
          message: "Edit successfully",
          type: "success",
        });
        this.getShareMessages()
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
}
::v-deep {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}
</style>