import {languages} from '@/biz/static-data' 
let from = {
  title: {
      en: null,
      zhHant: null,
      zhCn: null,
    },
    schedulePublishDateTime: null,
    isInstant: false,
    thumbnail: {
      en: null,
      zhHant: null,
      zhCn: null,
    },
    content: {
      en: null,
      zhHant: null,
      zhCn: null,
    },
    isPushToUser: false,
    pushMessage: {
      en: null,
      zhHant: null,
      zhCn: null,
    },
    type:0,
    links:[]
}
export function initializeForm(data=from) {
    from.schedulePublishDateTime = data.schedulePublishDateTime || null
    from.isInstant = data.isInstant || false
    from.isPushToUser = data.isPushToUser || false
    from.type = data.type || 0
    from.links = data.links || []
    languages.filter((key) => {
        from[`title${key}`] = data.title[key] || ''
        from[`content${key}`] = data.content[key] || ''
        from[`thumbnail${key}`] = data.thumbnail[key] || ''
        from[`pushMessage${key}`] = data.pushMessage[key] || ''
    });
    return from
}
export function transformForm(data) {
    from.schedulePublishDateTime = data.schedulePublishDateTime || null
    from.isInstant = data.isInstant || false
    from.isPushToUser = data.isPushToUser || false
    from.type = data.type || 0
    from.links = data.links || []
    languages.filter((key) => {
        from.title[key]= data[`title${key}`] || '',
        from.content[key]= data[`content${key}`] || '',
        from.thumbnail[key]= data[`thumbnail${key}`] || '',
        from.pushMessage[key]= data[`pushMessage${key}`] || ''

    });
    return from

}