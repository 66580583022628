<template>
  <div class="content">
    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="title_zhHant">
        <el-input v-model="ruleForm.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="title_zhCn">
        <el-input v-model="ruleForm.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line"><span class="span-label-text">*</span>Date</div>
      <el-form-item prop="date" :rules="[{ required: true, message: 'Please select the date', trigger: 'change' }]">
        <el-date-picker v-model="ruleForm.date" type="date" style="width: 40%" placeholder="- Please select -"></el-date-picker>
      </el-form-item>

      <div class="line">Content</div>
      <el-form-item label="English" prop="content_en">
        <Tinymce v-model="ruleForm.content_en"></Tinymce>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="content_zhHant">
        <Tinymce v-model="ruleForm.content_zhHant"></Tinymce>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="content_zhCn">
        <Tinymce v-model="ruleForm.content_zhCn"></Tinymce>
      </el-form-item>

      <div class="line">Push Notification and Share Message</div>
      <el-form-item>
        <el-checkbox v-model="ruleForm.push_message">Push to user (Send push notification to user)</el-checkbox>
      </el-form-item>
      <el-form-item label="English" prop="push_message_en">
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="push_message_zhHant">
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="push_message_zhCn">
        <el-input type="textarea" rows="6" v-model="ruleForm.push_message_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Link(s)</div>
      <div class="group" v-for="(its, idx) in ruleForm.documents" :key="idx">
        <img class="close" src="@/assets/images/close.png" alt="" @click="remove(idx)">

        <div class="value">
          <div class="type">
            <el-radio v-model="its.type" :label="0">PDF</el-radio>
            <el-radio v-model="its.type" :label="1">External Link</el-radio>
            <el-radio v-model="its.type" :label="2">Photo</el-radio>
            <el-radio v-model="its.type" :label="3">Video</el-radio>
          </div>
          <el-form-item label="Link - English" :prop="`documents[${idx}].link_en`">
            <edit-div v-model="its.link_en"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Traditional Chinese" :prop="`documents[${idx}].link_zhHant`">
            <edit-div v-model="its.link_zhHant"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Simplified Chinese" :prop="`documents[${idx}].link_zhCn`">
            <edit-div v-model="its.link_zhCn"></edit-div>
          </el-form-item> 
        </div>
        
      </div>

      <div class="add">
        <div class="add-btn" @click="add">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>

    </el-form>
  </div>
</template>

<script>
import rules from './rules'

export default {
  props: {
    form: {
      type: Object,
    }
  },
  data() {
    return {
      ruleForm: {},
      rules
    }
  },
  methods: {
    add() {
      this.ruleForm.documents.push({
        type: 0,
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
      })

      this.verifyLink
    },
    verifyLink() {
      let leng = this.ruleForm.documents.length
      for(let i = 0; i < leng; i++) {
        this.rules[`documents[${i}].link_en`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
        this.rules[`documents[${i}].link_zhHant`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
        this.rules[`documents[${i}].link_zhCn`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
      }
    },
    remove(idx) {
      this.ruleForm.documents.splice(idx, 1)
    }
  },
  created() {

    this.ruleForm = JSON.parse(JSON.stringify(this.form))
  },
  beforeDestroy() {
    this.$emit('unifiedData', 'content', this.ruleForm)
  }
}
</script>

<style lang="scss" scoped>
.group {
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  .close {
    width: 28px;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .label {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
  }

  .value {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    flex-wrap: wrap;

    & > * {
      width: 32%;
    }

    .type {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.add {
  width: 100%;
  margin: 30px 0 40px 0;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer; 

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}

</style>