<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Link Check"></Header-Back>
    <div class="page-title-subtext"><i class="el-icon-circle-check"></i>DONE</div>
    <el-button type="success" icon="el-icon-download" @click="onDownloadReport">Download Report</el-button>
    <exportExcel v-show="false" ref="orderExcel" :tableLabels="tableLabels" path="Link Check" fileName="Link Check" :labels="orderExcel" buttonTitle=""></exportExcel>
    <loadingDialog :centerDialogVisible="centerDialogVisible"></loadingDialog>
  </div>
</template>

<script>
import exportExcel from '@/components/exportExcel'
import loadingDialog from '@/components/loadingDialog'
import {startLinkChecks,getLinkChecks} from '@/api/report'
export default {
  components: {
    loadingDialog,
    exportExcel
  },
  data() {
    return {
      centerDialogVisible:false,
      tableLabels:[
        {
          label:'Content category',
          prop:'materialType'
        },
        {
          label:'Content ID',
          prop:'materialId'
        },
        {
          label:'URL',
          prop:'url'
        },
        {
          label:'Content Type',
          prop:'contentType'
        },
        {
          label:'HTTP codeStatus',
          prop:'httpStatusCode'
        },
        {
          label:'status',
          prop:'status'
        },
        {
          label:'Check Datetime',
          prop:'checkDatetime'
        }
      ],
      isDownload:false,
      orderExcel:[],
      tableData:[],
    };
  },
  methods:{
    linkCheckStatus(v){
      switch (v) {
        case 0:
          return 'Idle';
        case 1:
          return 'Completed';
        case 2:
          return 'Failed';
        case 3:
          return 'Unexpected errors';
        default:
          break;
      }
    },
    onDownloadReport(){
      this.centerDialogVisible = true
      this.getLinkChecks()
    },
    getLinkChecks(){
      getLinkChecks().then((v)=>{
        this.orderExcel = v.data
        this.$refs.orderExcel.onButtonTitleButton()
      }).finally(()=>{
        this.centerDialogVisible = false
      })
    },
    startLinkChecks(){
      startLinkChecks().then(()=>{
        this.isDownload = true
        this.getLinkChecks()
      }).finally(()=>{
        this.centerDialogVisible = false
      })
    },
    onStartCheck(){
      this.centerDialogVisible = true
      this.startLinkChecks()
    },
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box{
    padding-top:30px;
    border-top: 1px solid #D9D9D9;
}
.page-title-subtext{
    margin-bottom: 20px;
    color: #000;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.page-title-subtext i {
  color: #1A74B0;
  font-size: 30px;
  margin-right: 10px;
}
</style>