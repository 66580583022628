import {languages} from '@/biz/static-data' 
let from = {
  id: null,
  type: 0,
  content: {
    en: null,
    zhHant: null,
    zhCn: null,
  },
}
export function initializeForm(data=from,type) {
    let newData = {}
    if(data!=null || data.length>0){
      newData = data.filter((item) => {
          return item.type ==  Number(type);
      })[0];
    }
    from.id = newData.id || null 
    from.type = newData.type 
    languages.filter((key) => {
        from[`content${key}`] = newData.content[key] || ''
    });
    return from
}
export function transformForm(data) {
    from.id = data.id || null
    from.type = data.type
    languages.filter((key) => {
        from.content[key]= data[`content${key}`] || ''

    });
    return from

}