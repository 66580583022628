<template>
  <div>
    <Header-Back  pageTitle="Create New"></Header-Back>
    <newFrom :form="form" ref="form"></newFrom>
    <el-button type="success" class="success-button" @click="onCreate">Create</el-button>
  </div>
</template>

<script>
import {addNews} from "@/api/news";
import newFrom from "./components/newFrom";
import {initializeForm,transformForm} from './newDataForm'
export default {
  components: { newFrom },
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.initializeForm();
  },
  methods: {
    onCreate(){
       this.$refs.form.$refs.form.validate((valid) => {
          if (valid) {
            this.addNews()
          } else {
            this.$nextTick(() => {
              const isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({
                // 滚动到指定节点
                block: 'center',
                behavior: 'smooth'
              })
            })
            return false;
          }
        });
    },
    initializeForm() {
      let that =this
      that.form = initializeForm()
    },
    addNews(){
      addNews(transformForm(this.form)).then(()=>{
          this.$router.push({path:'/new'} )
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.success-button{
  margin-top: 20px;
}
</style>