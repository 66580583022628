import exportToExcel from "@/utils/exportExcel";

let tHeader = [
  'ID', 
  'Title_EN', 'Title_TC', 'Title_SC',
  'Date', 
  'Content_EN', 'Content_TC', 'Content_SC',
  'Push to user', 'Push message_EN', 'Push message_TC', 'Push message_SC',
];

export default function exportExcel(tableData) {
  let linkLeng = 0
  let pushList = []
  tableData.filter((its) => {
    let { documents } = its;
    // 计算link最大量
    linkLeng = Math.max(linkLeng, documents.length)
  })

  // 按顺序追加
  // 根据linkLeng追加表头 
  for(let i = 0; i < linkLeng; i++) {
    pushList = ['Link Type', 'Link_EN', 'Link_TC', 'Link_SC']
    if(i == 0) {
      tHeader.push(...pushList)
    } else {
      let index = ` ${i + 1}`
      pushList = pushList.map(row => row + index)
      tHeader.push(...pushList)
    }
  }

  // 处理data
  let data = handleData(tableData, linkLeng),
  title = 'Important Announcement'

  exportToExcel(data, title, tHeader, tHeader, () => {})
}

function handleData(tableData, linkLeng) {
  let data = [], linkObj = {}

  tableData.filter(its => {
    // links
    for(let i = 0; i < linkLeng; i++) {
      let linkRow = its.documents[i],
      types = ['PDF', 'External Link', 'Photo', 'Video']
    
      if(i == 0) {
        // 若不存在数据 默认值为'-'
        if(linkRow) {
          linkObj['Link Type'] = types[linkRow.type] || '-'
          linkObj['Link_EN'] = linkRow.link.en || '-'
          linkObj['Link_TC'] = linkRow.link.zhHant || '-'
          linkObj['Link_SC'] = linkRow.link.zhCn || '-'
        } else {
          linkObj['Link Type'] = '-'
          linkObj['Link_EN'] = '-'
          linkObj['Link_TC'] = '-'
          linkObj['Link_SC'] = '-'
        }
      } else {
        let index = ` ${i + 1}`
        // 若不存在数据 默认值为'-'
        if(linkRow) {
          linkObj['Link Type' + index] = types[linkRow.type] || '-'
          linkObj['Link_EN' + index] = linkRow.link.en || '-'
          linkObj['Link_TC' + index] = linkRow.link.zhHant || '-'
          linkObj['Link_SC' + index] = linkRow.link.zhCn || '-'
        } else {
          linkObj['Link Type' + index] = '-'
          linkObj['Link_EN' + index] = '-'
          linkObj['Link_TC' + index] = '-'
          linkObj['Link_SC' + index] = '-'
        }
      }
    }

    let obj = {
      'ID': its.id,
      'Title_EN': its.title.en,
      'Title_TC': its.title.zhHant,
      'Title_SC': its.title.zhCn,
      'Content_EN': its.content.en || '-',
      'Content_TC': its.content.zhHant || '-',
      'Content_SC': its.content.zhCn || '-',
      'Date': new Date(its.date).format('yyyy-MM-dd'),
      'Push to user': its.isPushToUser ? 'Yes' : 'No',
      'Push message_EN': its.pushMessage.en || '-',
      'Push message_TC': its.pushMessage.zhHant || '-',
      'Push message_SC': its.pushMessage.zhCn || '-',
      ...linkObj
    }

    data.push(obj)
  })
  return data
}